import React from 'react';

import './Leader.scss';

function Leader({ image, name, achievements, links }) {
  const renderAchievements = (achievements) => {
    return achievements.map((achievement, index) => (
      <p className="leaders__item-achievement" key={index}>
        {achievement}
      </p>
    ));
  };

  const renderLinks = (links) => {
    return links.map((link, index) => (
      <a
        href={link.url}
        className="leaders__item-link"
        key={index}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={link.site} alt=""/>
      </a>
    ));
  };

  return (
    <div className="leaders__item">
      <img
        src={image}
        alt={name}
        className="leaders__item-image"
      />
      <p className="leaders__item-name">
        {name}
      </p>
      <div className="leaders__item-achievements">
        {renderAchievements(achievements)}
      </div>
      <div className="leaders__item-links">
        {renderLinks(links)}
      </div>
    </div>
  );
}

export default React.memo(Leader);
