import React from 'react';

import MountainsImage from 'images/mountains.png';

import './Mountains.scss';

function Mountains() {
  return (
    <section className="mountains">
      <img src={MountainsImage} alt="mountains" className="mountains__image"/>
    </section>
  );
}

export default React.memo(Mountains);
