import React, { useEffect } from 'react';
import { Link } from 'gatsby';
import Typewriter from 'typewriter-effect';

import Design from 'images/showcase/design.svg';
import Code from 'images/showcase/code.svg';
import Guarantee from 'images/showcase/guarantee.svg';
import Launch from 'images/showcase/launch.svg';
import Discord from 'images/showcase/discord.svg';
import Github from 'images/showcase/github.svg';
import Reddit from 'images/showcase/reddit.svg';
import Twitter from 'images/showcase/twitter.svg';
import Youtube from 'images/showcase/youtube.svg';

import './Showcase.scss';

function Showcase({ home, tag, links }) {
  useEffect(() => {
    let i = 0, j = 0;

    const codeDesktopText = home.code;
    const codeMobileText = home.code.split('<br/>').slice(0, 8).join('<br/>');

    const typeWriter = () => {
      const codeDesktop = document.getElementById('code-desktop');
      const codeMobile = document.getElementById('code-mobile');

      if (codeDesktop) {
        if (i < codeDesktopText.length) {
          if (codeDesktopText.substring(i, i + 5) === '<br/>') {
            codeDesktop.innerHTML += '<br/>';
            i += 5;
          } else if (codeDesktopText.substring(i, i + 6) === '&nbsp;') {
            codeDesktop.innerHTML += '&nbsp;';
            i += 6;
          } else {
            codeDesktop.innerHTML += codeDesktopText.charAt(i);
            i++;
          }

          const highestTimeout = setTimeout(typeWriter, 25);
          clearTimeout(highestTimeout - 1);
        }
      }

      if (codeMobile) {
        if (j < codeMobileText.length) {
          if (codeMobileText.substring(j, j + 5) === '<br/>') {
            codeMobile.innerHTML += '<br/>';
            j += 5;
          } else if (codeMobileText.substring(j, j + 6) === '&nbsp;') {
            codeMobile.innerHTML += '&nbsp;';
            j += 6;
          } else {
            codeMobile.innerHTML += codeMobileText.charAt(j);
            j++;
          }
        }
      }
    };

    typeWriter();
  });

  return (
    <section className="showcase">
      <div className="container">
        <div className="showcase__inner">
          <div className="showcase__info">
            <h1 className="showcase__title">
              {home.blockchain_development_text} <span>{home.platform_text}</span>
            </h1>
            <p className="showcase__subtitle">
              {home.current_version_text}
            </p>
            <div className="showcase__items">
              <div className="showcase__item">
                <div className="showcase__item-image-wrapper">
                  <img
                    src={Design}
                    alt={home.design_quickly_text}
                    className="showcase__item-image"
                  />
                </div>
                <p className="showcase__item-text">
                  {home.design_quickly_text}
                </p>
              </div>
              <div className="showcase__item">
                <div className="showcase__item-image-wrapper">
                  <img
                    src={Code}
                    alt={home.code_easily_text}
                    className="showcase__item-image"
                  />
                </div>
                <p className="showcase__item-text">
                  {home.code_easily_text}
                </p>
              </div>
              <div className="showcase__item">
                <div className="showcase__item-image-wrapper">
                  <img
                    src={Guarantee}
                    alt={home.guarantee_safety_text}
                    className="showcase__item-image"
                  />
                </div>
                <p className="showcase__item-text">
                  {home.guarantee_safety_text}
                </p>
              </div>
              <div className="showcase__item">
                <div className="showcase__item-image-wrapper">
                  <img
                    src={Launch}
                    alt={home.launch_anywhere_text}
                    className="showcase__item-image"
                  />
                </div>
                <p className="showcase__item-text">
                  {home.launch_anywhere_text}
                </p>
              </div>
            </div>
            <div className="showcase__buttons">
              <a
                href="https://docs.reach.sh/"
                className="showcase__button showcase__button--get-started button button--red"
                target="_blank"
                rel="noreferrer"
              >
                {home.documentation_button}
              </a>
            </div>
          </div>
          <div className="showcase__code">
            <div className="showcase__code-circles">
              <div className="showcase__code-circle" />
              <div className="showcase__code-circle" />
              <div className="showcase__code-circle" />
            </div>
            {/* <p id="code-desktop" /> */}
            <Typewriter
              onInit={(typewriter) => {
                typewriter.typeString('<span class="string-highlight">\'reach 0.1\';</span><br><br>')
                  .typeString('<span class="keyword-highlight">export const</span> main =<br>')
                  .typeString('&nbsp;&nbsp;&nbsp;Reach.<span class="function-highlight">App</span>(<br>')
                  .typeString('&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{},<br>')
                  .typeString('&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;[<span class="function-highlight">Participant</span>(<span class="string-highlight">\'Alice\'</span>, { <span class="key-highlight">request</span>: UInt,<br>')
                  .typeString('&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="key-highlight">info</span>: <span class="function-highlight">Bytes</span>(128) }),<br>')
                  .typeString('&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="function-highlight">Participant</span>(<span class="string-highlight">\'Bob\'</span>, { <span class="key-highlight">want</span>: <span class="function-highlight">Fun</span>([UInt], <span class="keyword-highlight">Null</span>),<br>')
                  .typeString('&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="key-highlight">got</span>: <span class="function-highlight">Fun</span>([<span class="function-highlight">Bytes</span>(128)], <span class="keyword-highlight">Null</span>) })],<br>')
                  .typeString('&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(<span class="param-highlight">A</span>, <span class="param-highlight">B</span>) => {<br>')
                  .typeString('&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(A.<span class="function-highlight">only</span>(() => {<br>')
                  .typeString('&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="keyword-highlight">const</span> request = <span class="function-highlight">declassify</span>(interact.request); });<br>')
                  .typeString('&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A.<span class="function-highlight">publish</span>(request);')
                  .start();
                }
              }
            />
          </div>
          <div className="showcase__code-mobile">
            {/* <p id="code-mobile" /> */}
            <Typewriter
              onInit={(typewriter) => {
                typewriter.typeString('<span class="string-highlight">\'reach 0.1\';</span><br><br>')
                  .typeString('<span class="keyword-highlight">export const</span> main =<br>')
                  .typeString('&nbsp;&nbsp;&nbsp;Reach.<span class="function-highlight">App</span>(<br>')
                  .typeString('&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{},<br>')
                  .typeString('&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;[<span class="function-highlight">Participant</span>(<span class="string-highlight">\'Alice\'</span>, { <span class="key-highlight">request</span>: UInt,<br>')
                  .typeString('&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="key-highlight">info</span>: <span class="function-highlight">Bytes</span>(128) }),<br>')
                  .typeString('&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="function-highlight">Participant</span>(<span class="string-highlight">\'Bob\'</span>, { <span class="key-highlight">want</span>: <span class="function-highlight">Fun</span>([UInt], <span class="keyword-highlight">Null</span>),')
                  .start();
              }
              }
            />
          </div>
          <div className="showcase__mobile-buttons">
            {/* <Link
              to={`/${tag}/resources/`}
              state={{ tab: 1 }}
              className="showcase__mobile-button showcase__mobile-button--get-started button button--red"
            >
              {home.get_started_button}
            </Link> */}
            <a
              href="https://docs.reach.sh"
              state={{ tab: 0 }}
              className="showcase__mobile-button showcase__mobile-button--documentation button button--red"
            >
              {home.documentation_button}
            </a>
          </div>
        </div>
      </div>
      <div className="showcase__links">
        <a
          href={links.discord}
          className="showcase__link"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={Discord} alt="discord" />
        </a>
        <a
          href={links.github}
          className="showcase__link"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={Github} alt="github" />
        </a>
        <a
          href={links.reddit}
          className="showcase__link"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={Reddit} alt="reddit" />
        </a>
        <a
          href={links.youtube}
          className="showcase__link"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={Youtube} alt="youtube" />
        </a>
        <a
          href={links.twitter}
          className="showcase__link"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={Twitter} alt="twitter" />
        </a>
      </div>
    </section>
  );
}

export default React.memo(Showcase);
