import React from 'react';

import './FeaturedItem.scss';

function FeaturedItem({
  href,
  image,
  title,
  description,
  tags
}) {
  const tagsColor = tags.backgroundColor;

  const renderTagsList = (list) => {
    return list.map(tag => (
      <div
        className={`featured__item-tag featured__item-tag--${tagsColor}`}
        key={tag.id}
      >
        {tag.title.toUpperCase()}
      </div>
    ));
  };

  return (
    <a
      className="featured__item"
      href={href}
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        src={image}
        alt={title}
        className="featured__item-image"
      />
      <p className="featured__item-title">
        {title}
      </p>
      <p className="featured__item-description">
        {description}
      </p>
      <div className="featured__item-tags">
        {renderTagsList(tags.list)}
      </div>
    </a>
  );
}

export default React.memo(FeaturedItem);
