import React from 'react';
import Slider from "react-slick";
import Leader from 'components/Leader';

import Discord from 'images/showcase/discord.svg';
import Github from 'images/showcase/github.svg';
import Reddit from 'images/showcase/reddit.svg';
import Twitter from 'images/showcase/twitter.svg';
import YouTube from 'images/showcase/youtube.svg';

import './Leaders.scss';
import '../../../node_modules/slick-carousel/slick/slick.css';
import '../../../node_modules/slick-carousel/slick/slick-theme.css';

function getSite(type) {
  switch (type) {
    case 'Discord':
      return Discord;
    case 'GitHub':
      return Github;
    case 'Reddit':
      return Reddit;
    case 'Twitter':
      return Twitter;
    case 'YouTube':
      return YouTube;
    default:
      return;
  }
}

function Leaders({ page, discord, common, leaders, images, buttonColor }) {
  const sectionClass = `leaders${(page || '') && ' leaders--' + page}`;

  images = images.map(image => image.photo.childImageSharp.fixed.src);

  const renderLeadersItems = leaders.map((leader, index) => (
    <Leader
      image={images[index]}
      name={leader.full_name}
      achievements={leader.description.split('\n')}
      links={leader.links.map(link => ({
        site: getSite(link.type),
        url: link.url
      }))}
      key={index}
    />
  ));

  const sliderSettings = {
    className: 'center',
    centerMode: true,
    centerPadding: '130px',
    infinite: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 500,
        settings: {
          centerPadding: '110px'
        }
      },
      {
        breakpoint: 420,
        settings: {
          centerPadding: '90px'
        }
      },
      {
        breakpoint: 360,
        settings: {
          centerPadding: '70px'
        }
      }
    ]
  };

  return (
    <section className={sectionClass}>
      <div className='container container--narrow leaders__container'>
        <p className='leaders__title section-title'>
          {common.developer_community_title_text}
        </p>
        {/* <p className='leaders__subtitle-mobile'>
          {common.developer_community_title_index_text}
        </p> */}
        <p className='leaders__description'>
          {common.developer_community_subtitle_text}
        </p>
        {/* <p className='leaders__subtitle'>
          {common.developer_community_title_index_text}
        </p> */}
        <div className='leaders__list'>
          <p className='leaders__list-title'>
            {common.community_leaders_text}
          </p>
          <div className='leaders__items'>
            {renderLeadersItems}
          </div>
          <div className='leaders__slider'>
            <Slider {...sliderSettings}>
              {renderLeadersItems}
            </Slider>
          </div>
          <div className='leaders__join-wrapper'>
            <a
              href={discord}
              className={`leaders__join button button--${buttonColor}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {common.join_the_community_button}
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default React.memo(Leaders);
