import React, { useEffect } from 'react';

import './Aurora.scss';

function Aurora() {
  const generateRandomNumber = (min, max) => {
    const randomNumber = Math.random() * (max - min) + min;

    return randomNumber === min ? generateRandomNumber(min, max) : randomNumber;
  };

  const generateRandomInteger = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min);
  };

  const generateStars = () => {
    const scene = document.querySelector('.scene');

    for (let i = 0; i < 700; i++) {
      const codeToInsert = `
        <div class="star" style="
          --size: ${generateRandomNumber(0, 2)};
          --x: ${generateRandomNumber(0, 100)};
          --y: ${generateRandomNumber(0, 1130)};
          --duration: ${generateRandomInteger(5, 10)};
          --delay: ${generateRandomInteger(4, 10)};
        ">
        </div>
      `;

      scene.insertAdjacentHTML('afterbegin', codeToInsert);
    }
  };

  const generateLights = () => {
    const lights = document.querySelector('.lights');

    for (let i = 0; i < 40; i++) {
      const codeToInsert = `
        <div class="light" style="
          --duration: ${generateRandomInteger(5, 15)};
          --delay: ${generateRandomInteger(4, 10)};
          --x: ${generateRandomInteger(0, 5)};
          --y: ${generateRandomInteger(0, 10)};
          --scale: ${generateRandomNumber(0, 1)};
        "></div>
      `;

      lights.insertAdjacentHTML('afterbegin', codeToInsert);
    }
  };

  useEffect(() => {
    generateStars();
    generateLights();
  });

  return (
    <div className="scene" style={
      {
        '--hue-1': '89',
        '--hue-2': '76',
        '--hue-3': '262',
        '--alpha-1': generateRandomNumber(0, 1),
        '--alpha-2': generateRandomNumber(0, 1),
        '--alpha-3': generateRandomNumber(0, 1)}
      }>
      <div className="hill"></div>
      <div className="moon"></div>
      <div className="lights"></div>
    </div>
  );
}

export default React.memo(Aurora);
