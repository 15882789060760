import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Advantage from 'components/Advantage';

import './Advantages.scss';

function Advantages({ page, cards }) {
  const sectionClass = `advantages${(page || '') && ' advantages--' + page}`;

  const { strapiHome } = useStaticQuery(graphql`{
    strapiHome {
      cards {
        icon {
          publicURL
        }
      }
    }
  }`)

  const images = strapiHome.cards.map(card => card.icon.publicURL)

  return (
    <section className={sectionClass}>
      <div className="container">
        <div className="advantages__items">
          {cards.map((card, index) => (
            <Advantage
              image={images[index]}
              title={card.title}
              description={card.description}
              key={index}
            />
          ))}
        </div>
      </div>
    </section>
  );
}

export default React.memo(Advantages);
