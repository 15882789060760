import React from 'react';

import './Advantage.scss';

function Advantage({
  image,
  title,
  description
}) {

  return (
    <div className="advantages__item">
      <div className="advantages__item-image-wrapper">
        <img
          src={image}
          alt={title}
          className="advantages__item-image"
        />
      </div>
      <p className="advantages__item-title">
        {title}
      </p>
      <p className="advantages__item-description">
        {description}
      </p>
    </div>
  );
}

export default React.memo(Advantage);
