import React  from 'react';
import FeaturedItem from 'components/FeaturedItem';

import './FeaturedContent.scss';

function FeaturedContent({ page, common, contents, images }) {
  const sectionClass = `featured${(page || '') && ' featured--' + page}`;

  images = images.map(image => image.preview.childImageSharp.fixed.src);

  const renderContent = (content) => content.map((item, index) => (
    <FeaturedItem
      href={item.link}
      image={images[index]}
      title={item.title}
      description={item.description}
      tags={{
        list: item.tags.map((tag, index) => ({
          id: index,
          title: tag
        })),
        backgroundColor: 'aquamarine'
      }}
      key={index}
    />
  ));

  return (
    <section className={sectionClass}>
      <div className="container container--narrow">
        <p className="featured__title section-title">
          {common.community_content_title_text}
        </p>
        <p className="featured__subtitle">
          {common.community_content_subtitle_text}
        </p>
        <div className="featured__items">
          {renderContent(contents)}
        </div>
      </div>
    </section>
  );
}

export default React.memo(FeaturedContent);
