import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Helmet } from 'react-helmet';
import Layout from 'components/Layout';
import Leaders from 'components/Leaders';
import Aurora from 'components/Aurora';
import Showcase from 'components/Showcase';
import Advantages from 'components/Advantages';
import Mountains from 'components/Mountains';
import FeaturedContent from 'components/FeaturedContent';
import FAQ from 'components/FAQ';

import '../styles/global.scss';

export default function HomePage({ pageContext: { home, common, faqs, links, navigation, tag, docsLink } }) {
  const { strapiHome } = useStaticQuery(graphql`{
    strapiHome {
      leaders {
        photo {
          childImageSharp {
            fixed(width: 120, height: 120) {
              src
            }
          }
        }
      }
      community_contents {
        preview {
          childImageSharp {
            fixed(width: 281, height: 142) {
              src
            }
          }
        }
      }
    }
  }`);

  return (
    <>
    <Helmet>
      <meta property='og:image' content='/seo/preview.jpg' />
      <meta property='og:image:width' content={820} />
      <meta property='og:image:height' content={540} />
    </Helmet>
    <Layout
      page='home'
      isHeaderTransparent
      links={links}
      navigation={navigation}
      tag={tag}
      metaTitle='Reach Blockchain Development Platform'
      metaDescription='The Reach platform makes blockchain development easy and accessible. Launch on any network, verifying the safety and correctness of your code as it compiles.'
    >
      <Aurora />
      <div className='animatable'>
        <div className='animatable__blue' />
        <Showcase
          home={home}
          tag={tag}
          links={links}
        />
        <Advantages
          page='home'
          cards={home.cards}
        />
        {/* <Feedbacks
          page='home'
          reviews={home.reviews}
        />
        <UseCases
          page='home'
          useCases={home.use_cases}
          common={common}
          tag={tag}
        /> */}
      </div>
      <div className='hidden-sky'>
        <Mountains />
        {/* <Leaders
          page='home'
          discord={links.discord}
          common={common}
          leaders={home.leaders}
          images={strapiHome.leaders}
          buttonColor='aquamarine'
        />
        <FeaturedContent
          page='home'
          common={common}
          contents={home.community_contents}
          images={strapiHome.community_contents}
          buttonText={home.resources_button}
          tag={tag}
        /> */}
        <FAQ
          title={common.faq_title_text}
          learnMore={common.learn_more_about_it_on_text}
          faqs={faqs}
          page='home'
        />
      </div>
    </Layout>
    </>
  );
}
